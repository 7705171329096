.spinner {
  display: inline-block;
  text-align: center !important;
}

.spinner img {
  width: 20px;
  /* margin-right: 0.4rem; */
  margin: 0 auto !important;
}
