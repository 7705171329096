@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}

 
/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    
    border: none;
    outline: none;
    
     
}

.card4:hover + .slick-prev,
.card4:focus + .slick-prev,
.card4:hover + .slick-next,
.card4:focus + .slick-next {
    
    display: block;
}

 

 
    .slick-prev:before,
    .slick-next:before {
       
        font-size: 40px;
        
        color: black;
        display: none;
       
    }





.slick-prev
{
    left: -65px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: 20px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
 
.latest-slide .slick-dots {
    bottom: -2rem;
}
.slick-dots
{
    position: absolute;
    bottom: 1rem;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;
    color: black;
    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    color: black;
    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: black;
    border: 0;
    outline: none;
    background: transparent;
}
 