.nav {
  /* position: fixed !important; */
  z-index: 99;
  width: 100%;
  background-color: white;
  color: black;
  /* padding: 1rem 2rem; */
  border: 1px solid rgba(128, 128, 128, 0.103);
}

.nav-logo {
  height: 4rem;
  width: 9rem;
  /* margin-left: 1rem !important; */
  /* margin-top: -0rem; */
}
.navbar-default {
  /* padding-top: 2rem; */
  align-self: center;
  padding: 1rem 2rem;
}
.navbar-default .navbar-nav > .active > ul li {
  border-radius: 0;
  align-self: center;
  color: #c3a166;
}

.navbar-default .navbar-nav li a {
  letter-spacing: 2px;
  color: black;

  align-self: center;
}
.hero-image-services {
  width: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* filter: brightness(50%); */
  /* opacity: 0.8; */
}
.hero-image-contact {
  /* height: 59rem; */
  width: 100%;
  /* margin-left: -5%;*/
  margin-top: 0rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* filter: brightness(50%); */
  /* opacity: 0.8; */
}
.image {
  filter: brightness(50%);
}
.navbar-default .navbar-nav li a:hover,
.navbar-default .navbar-nav li a:focus {
  outline: 0;
  color: #c3a166;
}

.signin-signup {
  position: fixed;
  top: 8%;
  left: 5%;

  background-color: white;
  /* transform: translate(0%, -50%); */
  width: 90%;
  transition: 0.2s;
  /* color: white; */
}
.sticky {
  position: fixed;
  top: 0;
  width: 90%;
  animation-duration: 4s;
  transition: 0.2s;
  left: 5%;
}
.invisible-input {
  display: none;
}
.login-heading {
  text-align: center;
  font-weight: bold;
  margin: 1rem 0rem;
  font-size: 20px;
  /* color: #f1662e; */
  color: #271a13;
}
.popup-login {
  z-index: 999;
}
.rmd-dialog {
  z-index: 999;
}
