.slick-dots li {
  background-color: #c3a166;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  transition-duration: 0.5s;
}

.slick-dots {
  /* position: relative; */
  /* bottom: 15%;
    left: -45%; */
}

.slick-dots li.slick-active {
  color: #090e48;

  background-color: #271a13;
}

.slick-dots li button::before {
  content: none;
}

.carousel-caption {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.carousel-caption .caption-head,
.carousel-caption .caption-desc {
  text-shadow: 1px 1px #000000;
}

/* Carousel Arrows */

.main-slide .carousel-nav-btn {
  /* background-color: white; */
  border-radius: 50%;
  height: 45px;
  width: 45px;
  border: none;
  padding-top: 15px;
  padding-left: 14px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  margin-top: -17px;
}

.main-slide .slick-arrow {
  display: block;
  position: absolute;
  top: 50%;
  font-size: 17px;
  height: 20px;
  width: 20px;
  z-index: 1;
  transform: translateY(-50%);
}

.main-slide .next-arrow {
  left: -3rem;
  display: block;
  cursor: pointer;
  color: #c3a166;
  /* z-index: ; */
}
.main-slide .prev-arrow {
  right: -1rem;
  display: block;
  cursor: pointer;
  color: #c3a166;
}

/* latest card slider  */

.slick-dots li {
  background-color: #c3a166;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  transition-duration: 0.5s;
}

.slick-dots {
  /* position: relative; */
  /* bottom: 15%;
    left: -45%; */
}

.slick-dots li.slick-active {
  color: #090e48;

  background-color: #271a13;
}

.slick-dots li button::before {
  content: none;
}

.carousel-caption {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.carousel-caption .caption-head,
.carousel-caption .caption-desc {
  text-shadow: 1px 1px #000000;
}

/* Carousel Arrows */

.about .carousel-nav-btn {
  background-color: black;
  opacity: 0.7;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: none;
  padding-top: 12px;
  padding-left: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: -17px;
}

.about .slick-arrow {
  display: block;
  position: absolute;
  top: 50%;
  font-size: 17px;
  height: 20px;
  width: 20px;
  z-index: 1;
  transform: translateY(-50%);
}

.about .next-arrow {
  left: 2rem;
  display: block;
  cursor: pointer;
  color: #c3a166;
  /* z-index: ; */
}
.about .prev-arrow {
  right: 3rem;
  display: block;
  cursor: pointer;
  color: #c3a166;
}
