.collapsible:after {
    font-family: "Font Awesome 5 Free"; 
    background-color: #292929;
    content: '\f107';
    font-size: 1.7rem;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
}

.collapsible.active:after {
    content: "\f106"; /* Unicode character for "minus" sign (-) */
}