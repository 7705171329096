#tabs li{
    color: #FFFFFF;
}

#tabs li.active{
    border-right: 1px;
    border-left: 1px;
    border-top: 1px;
    margin-bottom: -1px;
    background-color: #FFFFFF;
    color: #000000;
}

#tab-contents div.content-wrapper{
    display: none;
}

#tab-contents div.content-wrapper.active{
    display: block;
}

table.order-info tr td:first-child{
    padding-right: 0.5rem;
}

table.order-info tr{
    margin-bottom: 1rem;
}

table.order-info tr td{
    padding-left: 0.5rem;
}

.sticky-cart{
    position: sticky;
    top: 0.5rem;
    border: 1px solid #ddd;
    padding: 1rem;
}

.place-order-collapsible .icon::before {
    font-family: "Font Awesome 5 Free"; 
    background-color: #2D3748;
    content: '\f107';
    font-size: 1rem;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
}

.place-order-collapsible.active .icon::before {
    content: "\f106"; /* Unicode character for "minus" sign (-) */
}

.place-order-page .close3{
    padding-right: 1rem !important;
}