.modal-overlay {
    background-color: rgb(0, 0, 0, 0.75);
    width: 100vw;
    height: 100vh;
    z-index: 999;
    top: 0;
    left: 0;
    position: fixed;
}

.modal-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.modal-body {
    padding: 2.5rem 2rem 2rem 2rem;
    height: 100vh;
    min-width: 50vw;
    overflow-y: scroll;
    background-color: #ffffff;
}

.modal-close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: #ff0000;
}

@media (max-width: 576px) {
    .modal-body {
        padding: 2.5rem 1rem 1rem 1rem;
        min-width: 100vw;
    }
}
