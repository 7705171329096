.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  transform: translate3d(50px, 0px, 0px) !important;
}

.react-datepicker__input-container input {
  color: #000000;
  padding: 0.3rem 0.6rem;
}

.header-contact-info {
  position: absolute;
  top: 2%;
  right: 5%;
}

ul.footer-pages-links li {
  margin-bottom: 0.8rem;
}

ul.footer-pages-links li:hover a {
  text-decoration: underline;
  color: #ffffff;
}

.privacy-policy {
  text-transform: none;
}

.privacy-policy p {
  margin-bottom: 0.5rem;
}

.privacy-policy ul {
  list-style-type: disc;
  padding-left: 2rem;
}

.privacy-policy h1,
.privacy-policy h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.tc-pp-section {
  margin-bottom: 2rem;
}

.tc-pp-section ul.list-disc {
  list-style-type: disc;
}

.tc-pp-section ul {
  list-style-type: upper-greek;
  padding-left: 3rem;
  margin-top: 1rem;
}

.tc-pp-section ul li {
  text-transform: none;
  margin-bottom: 1rem;
}

.mail {
  color: #c3a166;
}
.contactContainer {
  z-index: 9;
}
.homepage-note {
  font-style: italic;
  color: beige;
}
.our-story-text {
  font-style: italic;
  color: #c3a166;
}
.buy-online-btn {
  background-color: #292929;
  padding: 0.5rem 1rem;
}
.menu-homepage-div {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cart-homepage-div {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about-homepage-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.homepage-div {
  position: absolute;
  top: 18%;
  left: 5%;
}
.contact-homepage-div {
  position: absolute;
  top: 35%;
  left: 5%;
}
.brush-img {
  position: absolute;
  top: 61%;
  left: 0%;
  /* height: 40%; */
}
.our-story-img {
  position: relative;
  top: 10%;
  right: 8%;
  height: 75%;
  width: 100%;
}
.story-img {
  margin-right: 0;
  position: absolute;
  right: 0;
  width: 25%;
  /* height: 100%; */
}
.explore-btn {
  background-color: #292929;
  margin-top: 2rem;
}
.few-words-txt {
  color: #323246;
}
.wave-img {
  bottom: 0rem;
  margin-top: 19rem;
  margin-left: -3.5rem;
  /* position: absolute; */
}
.openinghours-container {
  margin-top: 3rem;
}
.whypeaople-container {
  margin-top: 6rem;
}
.about-image-slider {
  /* width: 140%; */
}
.flower-img1 {
  background-color: #303030;
  position: relative;
  height: 100%;
  width: 100%;
  filter: brightness(70%);
  object-fit: contain;
}
.happy-img {
  filter: brightness(70%);
}
.upcoming-flower-img1 {
  background-color: #303030;
  position: relative;
  height: 116.2%;
  object-fit: contain;
}

.flower-img {
  background-color: #303030;
  position: relative;
  /* height: auto; */

  /* width: 100%; */
  /* height: 100%; */
}
.upcoming-flower-img {
  background-color: #303030;
  /* position: relative;
  height: 35% !important; */
  height: 17rem;
}
.opening-hour-right-side-div {
  background-color: #292929;
  border: 1px dashed gray;
  position: absolute;
  top: 8.5%;
  right: 7.5%;
  width: 85%;
  height: auto;
  padding: 1rem 3rem;
}
.upcoming-right-side-div {
  background-color: #292929;
  border: 1px dashed gray;
  position: absolute;
  top: 8%;
  left: 7%;
  width: 85%;
  /* height: 120%; */
  height: auto;
  padding: 1rem 3rem;
}
/* .opening-hour-left-side-div {
  position: absolute;
  top: 30%;
  padding: 3rem;
} */
/* .upcoming-left-side-div {
  position: absolute;
  top: 30%;
  left: 10%;
  padding: 3rem;
} */
.line {
  border-bottom: 1px solid #c3a166;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  width: 100%;
}
.menu-line {
  border-top: transparent !important;
  border-bottom: 0.1px dotted #c3a166 !important;
  border-left: transparent;
  border-right: transparent;
  width: 100%;
  /* margin-left: 2rem; */
  height: 0.8rem;
}
.number-text {
  border: 1px solid #c3a166;
  width: 70%;
  margin: auto;
}
.book-table-text {
  border: 1px solid #c3a166;
  /* width: 70%; */
  margin: auto;
  padding: 10px;
  background-color: #343434;
}
.footer {
  background-color: #292929;
  margin-top: 2rem;
}
.specialities-container {
  background-color: #292929;
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.happy-customer-img {
  position: absolute;
  width: 100%;
}
.total-members {
  position: relative;
  margin-top: 6rem;
}
.happycustomer-img {
  position: relative;
  top: 8rem;
  z-index: 999;
}
.happycustomer-img2 {
  position: relative;
  top: 1.2rem !important;
  z-index: 999;
}
.happycustomer-container {
  margin-top: 25rem;
  margin-bottom: 20rem;
}
.customerreview-container {
  margin: 15rem;
}
.footer-container {
  padding: 2rem 5rem;
}
.footer-line {
  border-bottom: 0.5px dotted #c3a166;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  width: 100%;
  margin: 3rem 0rem;
}
.middile-line {
  border-bottom: transparent;
  border-top: transparent;
  border-left: transparent;
  border-right: 0.5px dotted #c3a166;
  height: 80%;
  width: 10rem;
}
.contact-info-footer {
  line-height: 2rem;
}
.send-btn-footer {
  background-color: #c3a166;
  color: white;
  padding: 0.8rem 2rem;
  font-weight: bold;
}
.footer-send-input {
  background-color: #343434;
  color: grey;
  padding: 0.8rem 2rem;
  width: 100%;

  border: 1px solid rgba(128, 128, 128, 0.397);
}
.bottom-footer {
  background-color: #222222;
  padding: 2rem 5rem;
}

.back-to-top:hover span {
  display: none;
}
.back-to-top:hover:before {
  content: "\21EE";
  font-size: 40px;

  margin-top: -3rem;
}
.fa-facebook-f {
  color: #c3a166;
  background-color: #343434;
  padding: 10px 14px;
  border: 0.1px solid rgba(128, 128, 128, 0.562);
}

.fa-instagram {
  color: #c3a166;
  background-color: #343434;
  padding: 10px 14px;
  border: 0.1px solid rgba(128, 128, 128, 0.562);
}

.fa-vine {
  color: #c3a166;
  background-color: #343434;
  padding: 10px;
  border: 0.1px solid rgba(128, 128, 128, 0.562);
}

.fa-twitter {
  color: #c3a166;
  background-color: #343434;
  padding: 10px;
  border: 0.1px solid rgba(128, 128, 128, 0.562);
}
.whypeople-text {
  position: absolute;
  top: 50%;
  left: 10%;
  width: 80%;
}
.whypeople-text1 {
  position: absolute;
  top: 20%;
  left: 10%;
  width: 80%;
}
.whypeaople-img1 {
  position: relative;

  filter: brightness(50%);
}
.whypeaople-img {
  position: relative;
  opacity: 0.9;
  filter: brightness(50%);
}
.whypeaople-img:hover {
  transform: scaleY(-1);
}
.whypeople-line {
  border-top: 3px solid #c3a166;
  width: 50%;
  margin: 0 auto;
}
.back-text {
  width: 100%;
  height: 133%;
  background-color: #292929;
}
#toss {
  position: relative;
  margin: 3rem auto;
  /* width: 100%;
  height: 100%; */
  width: 370px;
  height: 181px;
  z-index: 1;
  perspective: 1000;
}
#rev {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.4s linear;
}
#toss:hover #rev {
  transform: rotateY(180deg);
}
.face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.face.back {
  display: block;
  transform: rotateY(180deg);
  box-sizing: border-box;
}
.read-text {
  background-color: #f9f9f9;
  padding: 1rem;
  border: 1px solid #c3a166;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
}
.checkout-menu-text {
  background-color: transparent;
  opacity: 0.7;
  color: white;
  padding: 1rem;
  border: 1px solid #c3a166;
  margin-left: auto;
  margin-right: auto;
}

.upcoming-line {
  border-bottom: 1px solid #c3a166;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  width: 100%;
  margin-left: 2.5rem;
}
.upcoming-line1 {
  border-bottom: 1px solid #c3a166;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  width: 100%;
  margin-left: -2rem;
}

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}
.menu-item:hover {
  color: #c3a166;
  cursor: pointer;
}
/* Change background color of buttons on hover */
.tab button:hover {
  color: #c3a166;
}
.tablinks.active {
  color: #c3a166;
}
.tablinks:hover {
  color: #c3a166;
}

/* Create an active/current tablink class */
.tab button.active {
  color: #c3a166;
}

/* Style the tab content */
.tabcontent {
  /* display: none; */
  padding: 6px 12px;
  /* border: 1px solid #ccc; */
  border-top: none;
}
.tabcontent {
  display: none;
}

.tabcontent.active {
  display: block;
}
.menu-right-side {
  width: 100%;
}
.add-to-cart:hover {
  background-color: white;
  color: black;
  /* display: block !important; */
}

.add-to-cart {
  background-color: transparent;
  color: transparent;
  width: 50%;
  /* display: none; */
}
.upcoming-container-div {
  margin-top: 5rem;
  margin-left: 15rem;
  margin-right: 15rem;
}
.menu-grid {
  background-color: #292929;
  color: white !important;
  border: 1px solid rgba(128, 128, 128, 0.144);
  padding-right: 0rem;
  padding-left: 0rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  z-index: 9;
}
.line-menu {
  border-bottom: 1px solid #c3a166;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  width: 100%;
}
.menuitems-container {
  border: 1px solid rgba(128, 128, 128, 0.144);
  background-color: #f9f9f9;
  height: 8rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.order-menuitems-container {
  border: 1px solid rgba(128, 128, 128, 0.144);
  background-color: #f9f9f9;
  height: 4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.product-name {
  height: 4rem;
}

.add-to-cart-menu-btn {
  background-color: white;

  width: 100%;
  padding: 0.6rem 0.5rem;
  border: 1px solid rgba(128, 128, 128, 0.144);
}
.add-to-cart-menu-btn-diabled-color {
  background-color: #dddddd;

  width: 100%;
  padding: 0.6rem 0.5rem;

  font-size: 25px;
  align-self: center;
  padding: 0rem 0.5rem;

  border: 1px solid rgba(128, 128, 128, 0.144);
}
.add-to-cart-menu-btn:hover {
  background-color: #c3a166;
  color: white;
}
.checked {
  color: #c3a166;
}
.sidemenu-top {
  /* background-color: #c3a166; */
  background-color: black;
  padding: 2rem;
  margin-top: -1rem;
}
.order-items-image {
  height: 7.9rem;
  width: 100%;
  background-size: cover;
}
.menu-items-image {
  height: 7.9rem;
  width: 100%;
  background-size: cover;
}
.plus-minus-bntn {
  font-size: 25px;
  align-self: center;
  padding: 0rem 0.5rem;
  background-color: white;
  border: 1px solid rgba(128, 128, 128, 0.144);
}
.menu-image-container {
  /* height: 100%; */
}
.brush-img-about {
  position: absolute;
  top: 58%;
  left: 0%;
}

/* .cart-image {
  position: relative;
  width: 100%;
  bottom: 50rem;
} */
.cartitems {
  padding: 0rem 4rem;
  z-index: 9;
}
.cart-item-right-container {
  background-color: #303030;
  padding: 1rem 1rem;
  margin-bottom: 7rem;
}
.cart-item-right-side {
  background-color: #292929;
  padding: 3rem 3rem;
  border: 1px dashed gray;
  color: white;
}
.line-cart {
  border-bottom: 1px solid gray;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  width: 100%;
}
.checkout-btn {
  background-color: #c3a166;
  color: white;
  width: 100%;
  padding: 0.8rem;
}
.place-order-btn {
  background-color: #c3a166;
  color: white;

  padding: 0.8rem;
}
.background-product {
  background-color: rgba(204, 204, 204, 0.164);
  border: 1px solid rgba(128, 128, 128, 0.048);
  /* align-self: center;*/
  text-align: center;
  padding: 0.5rem;
}
.background-description {
  border: 1px solid rgba(128, 128, 128, 0.048);
  /* align-self: center; */
  text-align: center;
  padding: 0.5rem;
}
.background-product-items {
  background-color: rgba(204, 204, 204, 0.164);
  border: 1px solid rgba(128, 128, 128, 0.048);
  /* align-self: center;*/
  text-align: center;
  padding: 1rem;
  height: 100%;
  /* width: 10%; */
}
.background-description-items {
  border: 1px solid rgba(128, 128, 128, 0.048);
  align-self: center;
  text-align: center;
  padding: 1rem;
  height: 100%;
  /* width: 115%; */
}
.blank-space {
  /* margin-top: 55rem; */
  height: 22rem;
}

.blank-space2 {
  height: 22rem;
}
.brush-img-contact {
  position: absolute;
  top: 95%;
  left: 0%;
}
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 650px;
  background: #292929 !important;
  /* opacity: 0.7; */
  filter: brightness(90%);
}
.gmap_canvas {
  overflow: hidden;
  /* background: none !important; */
  width: 100%;
  height: 650px;
  background-color: #292929 !important;
}
.gmap_iframe {
  width: 100% !important;
  height: 650px !important;
  background-color: #292929 !important;
}
.contact-item-right-side {
  background-color: #292929;
  padding: 2rem 3rem;
  border: 1px dashed gray;
  color: white;
}
.grid-opening {
  display: grid;
  grid-template-columns: auto auto;
}
.number-text {
  margin-top: 1rem;
}
.footer-grid {
  display: grid;
  grid-template-columns: auto auto auto;
}
.explore-container {
  margin-top: -20rem;
  z-index: 999;
}
.explore-text {
  z-index: 9;
}
.cube-image {
  z-index: 9;
}
@media (max-width: 1920px) {
  .nav {
    padding: 1.3rem 0rem;
  }
  .menu-container {
    margin-top: -22rem !important;
    position: relative;
    width: 1366px;
    margin: 0 auto;
  }
  .blank-space2 {
    height: 140rem;
  }
  .our-story-img {
    position: relative;
    top: 10%;
    right: 8%;
    height: 100%;
    width: 100%;
  }
  .openinghours-container {
    margin-top: 10rem;
  }
  .explore-container {
    margin-top: -25rem;
    z-index: 999;
  }
  .blank-space {
    /* margin-top: 55rem; */
    height: 25rem;
  }
  .blank-space-nav {
    height: 30rem;
  }
  .number-text {
    margin-top: 5rem;
  }
  .book-table-text {
    margin: 8rem 0rem;
  }
  .opening-hour-right-side-div {
    background-color: #292929;
    border: 1px dashed gray;
    position: absolute;
    top: 8.5%;
    right: 7.5%;
    width: 85%;
    height: 83%;
    padding: 1rem 3rem;
  }
  .upcoming-right-side-div {
    background-color: #292929;
    border: 1px dashed gray;
    position: absolute;
    top: 9%;
    left: 7%;
    width: 85%;
    /* height: 120%; */

    padding: 1rem 3rem;
  }
  .happycustomer-container {
    margin-top: 25rem;
    margin-bottom: 25rem !important;
  }
  .happycustomer-img {
    position: relative;
    top: 11.5rem !important;
    z-index: 999;
  }
  .why-people-container-card {
    width: 1215px !important;
    display: grid;
    grid-template-columns: auto auto auto auto;
    position: relative;
    margin: 0 auto;
  }
  .upcoming-flower-img {
    background-color: #303030;
    /* position: relative;
    height: 35% !important; */
    height: 32rem !important;
  }
}
@media (max-width: 1500px) {
  .menu-container {
    margin-top: -15rem !important;
    position: relative;
    width: 1366px;
    margin: 0 auto;
  }
  .our-story-img {
    position: relative;
    top: 10%;
    right: 8%;
    height: 80%;
    width: 100%;
  }
  .openinghours-container {
    margin-top: 3rem;
  }
  .explore-container {
    margin-top: -20rem;
  }

  .book-table-text {
    margin: 3rem 0rem;
  }
  .upcoming-right-side-div {
    height: auto !important;
  }
  .number-text {
    margin-top: 1rem !important;
  }
  .happycustomer-container {
    margin-top: 25rem;
    margin-bottom: 20rem !important;
  }
  .happycustomer-img {
    position: relative;
    top: 9rem !important;
    z-index: 999;
  }
  .why-people-container-card {
    width: auto !important;

    position: relative;
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-right: 0rem;
    margin-left: 4.5rem;
  }
  .blank-space {
    /* margin-top: 55rem; */
    height: 20rem;
  }
  .upcoming-flower-img {
    background-color: #303030;
    /* position: relative;
    height: 35% !important; */
    height: 19rem !important;
  }
}

@media (max-width: 1366px) {
  .menu-container {
    margin-top: -15rem !important;
    position: relative;
    width: auto;
    margin: 0 auto;
  }
  .book-table-text {
    margin: 2rem 0rem;
  }
  .happycustomer-img {
    position: relative;
    top: 8rem !important;
    z-index: 999;
  }
  .why-people-container-card {
    width: auto !important;

    position: relative;
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-right: 0rem;
    margin-left: 3.5rem;
  }
  .upcoming-flower-img {
    background-color: #303030;
    /* position: relative;
    height: 35% !important; */
    height: 17rem !important;
  }
}
@media (max-width: 1280px) {
  .upcoming-container-div {
    margin-top: 5rem;
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .our-story-img {
    position: relative;
    top: 10%;
    right: 8%;
    height: 70%;
    width: 100%;
  }
  .book-table-text {
    margin: 1rem 0rem;
  }
  .happycustomer-img {
    position: relative;
    top: 8rem !important;
    z-index: 999;
  }
  .why-people-container-card {
    width: auto !important;

    position: relative;
    margin: 0 3rem;
  }
  .upcoming-flower-img {
    background-color: #303030;
    /* position: relative;
    height: 35% !important; */
    height: 14.5rem !important;
  }
}
@media (max-width: 1024px) {
  .gallery-homepage {
    /* width: 200px;
  
  cursor: pointer; */
    filter: none !important;
    height: 290px;
    /* The least supported option. */
  }
  .products-grid-menu {
    display: grid;
    grid-template-columns: auto auto;
  }
  .upcoming-container-div {
    margin-top: 5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .grid-contact {
    display: grid;
    grid-template-columns: auto;
    margin-right: 1rem !important;
    margin-left: 1rem;
  }
  .menu-grid-menu-page {
    display: grid;
    grid-template-columns: auto;
  }
  .line-menu {
    display: none;
  }
  .our-story-img {
    position: relative;
    top: 30%;
    right: 0%;
    height: 50%;
    width: 100%;
    margin-left: 4rem;
  }
  .cube-image {
    display: none;
  }
  .explore-container {
    margin-top: 0rem;
    z-index: 999;
  }
  .brush-img {
    position: absolute;
    top: 90%;
    left: 0%;
    /* height: 40%; */
  }
  .story-img {
    display: none;
  }
  .grid-opening {
    display: grid;
    grid-template-columns: auto;
  }
  .why-people-container-card {
    width: auto !important;
    display: grid;
    grid-template-columns: auto;
    position: relative;
    margin: 2rem auto;
  }
  .footer-grid {
    display: grid;
    grid-template-columns: auto;
  }
  .number-text {
    margin-top: 5rem !important;
  }
  .upcoming-flower-img1 {
    background-color: #303030;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .book-table-text {
    margin: 2.5rem 0rem;
  }
  .signin-signup {
    position: fixed;
    top: 3%;
    left: 5%;

    background-color: white;
    /* transform: translate(0%, -50%); */
    width: 90%;
    transition: 0.2s;
    /* color: white; */
  }
  .sticky {
    position: fixed;
    top: 0;
    width: 90%;
    animation-duration: 4s;
    transition: 0.2s;
    left: 5%;
  }
  .openinghours-container {
    /* margin-top: 40rem; */
  }

  .menu-container {
    margin-top: -10rem !important;
  }
  .home-nav-item {
    font-size: 10px;
  }
  .slider-gallery-section {
    display: block !important;
  }
  .non-slider-gallery-section {
    display: none;
  }
  .upcoming-flower-img {
    background-color: #303030;
    /* position: relative;
    height: 35% !important; */
    height: 18rem !important;
  }
}
@media (max-width: 768px) {
  .footer-container {
    padding-top: 10rem;
  }
  .card-icon {
    margin: auto;
    /* margin-right: 1rem; */
  }

  .products-grid-menu {
    display: grid;
    grid-template-columns: auto;
  }
  .upcoming-container-div {
    margin-top: 5rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .speciality-items-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
  }
  .cartitems {
    padding: 0rem 2rem;
    z-index: 9;
  }
  .menu-homepage-div {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
  }
  .cart-homepage-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .about-homepage-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .menu-container {
    margin-top: -5rem !important;
  }
  .brush-img-about {
    position: absolute;
    top: 75%;
    left: 0%;
  }
  .few-words-txt {
    font-size: 25px;
  }
  .our-story-img {
    position: relative;
    top: 30%;
    right: 0%;
    height: 40%;
    width: 100%;
    margin-left: 4rem;
  }
  .openinghours-container {
    /* margin-top: 50rem; */
  }
  .middile-line {
    display: none;
  }

  .upcoming-right-side-div {
    background-color: #292929;
    border: 1px dashed gray;
    position: absolute;
    top: 8%;
    left: 7%;
    width: 86%;
    /* height: 120%; */
    height: auto;
    padding: 0rem 3rem;
  }
  .home-nav-item {
    display: none;
  }
  .menu-nav-item {
    display: block !important;
  }
  .backyard-logo {
    position: absolute;
    top: -0.5rem;
    margin: auto;
    margin-left: 5% !important;
    height: 10rem !important;
  }
  .nav {
    padding: 0rem 0rem;
  }
  .upcoming-flower-img {
    background-color: #303030;
    /* position: relative;
    height: 35% !important; */
    height: 13rem !important;
  }
}
@media (max-width: 600px) {
  .desktopview-btn {
    display: none;
  }
  .dialogboxbtnmobileview {
    color: #271a13;
    padding-left: 1rem;
    display: block !important;
  }
  .dialogboxbtnmobileview2 {
    padding-left: 1rem;
    display: block !important;
  }
  .footer-container {
    padding-top: 5rem !important;
  }
  .cart-item-right-container {
    background-color: #303030;
    padding: 1rem 1rem;
    margin-bottom: 0rem;
  }
  .gmap_canvas {
    overflow: hidden;
    /* background: none !important; */
    width: 100%;
    height: 250px;
    background-color: #292929 !important;
  }
  .gmap_iframe {
    width: 100% !important;
    height: 650px !important;
    background-color: #292929 !important;
  }
  .mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 250px !important;
    background: #292929 !important;
  }
  .order-product-name {
    height: 2rem;
  }
  .mobile-view {
    display: block !important;
  }
  .menu-items-image {
    /* height: 10rem; */
    width: 100%;
  }
  .backyard-logo {
    margin-left: 0% !important;
  }
  .upcoming-right-side-div {
    position: relative;

    padding: 3rem 3rem;
  }

  .note-opening {
    line-height: 1.5rem;
  }
  .mobileview-why-people {
    display: block !important;
  }
  .why-people-container-card {
    display: none;
  }
  .menu-image-container {
    height: auto;
  }
  .img-div-remove {
    display: none;
  }
  .contact-homepage-div {
    position: relative;
    margin-top: -7% !important;
    left: 0%;
  }
  .brush-img-contact {
    display: none;
  }
  div.scrollmenu {
    overflow: auto;
    /* display: none; */
    white-space: nowrap;
  }

  .menu-container {
    margin-top: -2rem !important;
  }
  .menu-homepage-div {
    line-height: 2rem;
    top: 62% !important;
  }
  .cart-homepage-div {
    line-height: 2rem;
    top: 60% !important;
  }
  .about-homepage-div {
    line-height: 2rem;
    top: 60% !important;
  }
  .bottom-footer {
    padding: 2rem 2rem;
  }
  .footer-container {
    padding: 2rem 2rem;
  }
  .speciality-items-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
  .our-story-img {
    position: relative;
    top: 0%;
    right: 0%;
    height: 100%;
    width: 90%;
    margin-left: 2rem;
  }
  .flower-upcoming-img {
    display: none;
  }
  .opening-hours {
    background-color: #303030;
    margin-top: 40rem;
  }
  .upcoming-hours {
    background-color: #303030;
    position: relative;
    /* margin-top: -35rem; */
  }
  .number-text {
    margin-top: 2rem !important;
  }
  .menu-line {
    display: none;
  }
  .homepage-heading {
    line-height: 2.5rem;
    font-size: 20px;
  }

  .order-items-image {
    height: 5.9rem !important;
    width: 5.9rem !important;
  }
  .order-menuitems-container {
    border: 1px solid rgba(128, 128, 128, 0.144);
    background-color: #f9f9f9;
    height: 4rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}
@media (max-width: 540px) {
  .backyard-logo {
    margin-left: -2% !important;
  }
}
@media (max-width: 414px) {
  .footer-container {
    padding-top: 9rem !important;
  }
  .contact-homepage-div {
    position: relative;
    margin-top: -11% !important;
    left: 0%;
  }
  .menuitems-container {
    height: 6rem;
  }

  .product-name {
    height: 2.5rem;
  }
  .menu-items-image {
    height: 5.9rem;
    width: 5.9rem;
  }
  .desktop-view-register {
    display: none;
  }
  .navbar-default {
    /* padding-top: 2rem; */
    align-self: center;
    padding: 1rem 1rem;
  }

  .backyard-logo {
    height: 8rem !important;
    margin-left: 0% !important;
  }
  .menu-grid {
    background-color: #292929;
    color: white;
    border: 1px solid rgba(128, 128, 128, 0.144);
    /* padding-right: 1rem;
    padding-left: 1rem; */
    padding-top: 0rem;
    padding-bottom: 0rem;
    z-index: 9;
  }
  /* .scrollmenu .tablinks {
    
    padding: 0 4rem !important;
  }
  .tablinks.active {
    padding: 0 4rem !important;
    font-size: 13px !important;
  }
  .tablinks:hover {
    padding: 0 4rem !important;

    font-size: 13px !important;
  } */
  .cartitems {
    padding: 0rem 0rem;
    z-index: 9;
  }
  .grid-contact {
    display: grid;
    grid-template-columns: auto;
    margin-right: 0rem !important;
    margin-left: 0rem;
    margin-top: -2rem;
  }
  .total-members {
    display: grid;
    grid-template-columns: auto;
  }
  .menu-container {
    margin-top: 5rem !important;
  }
  .brush-img-about {
    position: absolute;
    top: 100%;
    left: 0%;
  }
  .checkout-menu-text {
    padding: 0.5rem;
  }
  .speciality-items-grid {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .upcoming-line {
    margin-left: 0rem;
  }
  .upcoming-line1 {
    margin-left: 0rem;
  }
  .header-contact-info {
    display: none;
  }
  .signin-signup {
    position: fixed !important;
    top: 0%;
    left: 0%;

    background-color: white;
    /* transform: translate(0%, -50%); */
    width: 100% !important;
    /* transition: 0.2s; */
    /* color: white; */
  }
  .wave-img {
    display: none;
  }
  .our-story-img {
    margin-left: 1.3rem;
  }
  .hero-image-services {
    width: 100%;
    height: 130% !important;
    top: 2rem !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /* filter: brightness(50%); */
    /* opacity: 0.8; */
  }
  .brush-img {
    position: absolute;
    top: 105%;
    left: 0%;
    /* height: 40%; */
  }
  .explore-container {
    padding-top: 2rem;
  }

  .menu-homepage-div {
    position: absolute;
    top: 50%;
    left: 28%;
    transform: translate(-5%, -50%);
    font-size: 12px;
  }
  .about-homepage-div {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-0%, -50%);
    font-size: 12px;
  }
  .number-text {
    border: 1px solid #c3a166;
    width: auto;
    margin: auto;
  }
  .homepage-heading {
    line-height: 2.5rem;
    font-size: 20px;
  }
  .contact-item-right-side {
    background-color: #292929;
    padding: 1rem 1rem;
    border: 1px dashed gray;
    color: white;
  }
}
@media (max-width: 375px) {
  .nav-default {
    /* position: fixed !important; */

    padding: 1rem 1rem;
  }
  .menu-homepage-div {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-0%, -50%);
    font-size: 12px;
  }

  .opening-hour-right-side-div {
    padding: 1rem 2rem;
  }

  .cart-homepage-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .backyard-logo {
    margin-left: -2% !important;
  }
}
@media (max-width: 360px) {
  .backyard-logo {
    margin-left: -3% !important;
  }
  #toss {
    position: relative;
    margin: 3rem auto;
    /* width: 100%;
    height: 100%; */
    width: 280px;
    height: 281px;
    z-index: 1;
    perspective: 1000;
  }
  .homepage-div {
    position: absolute;
    top: 12%;
    left: 5%;
  }
  .homepage-heading {
    line-height: 2.5rem;
    font-size: 20px;
  }
  .our-story-img {
    margin-left: 1.1rem;
  }
  .menu-homepage-div {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-0%, -50%);
    font-size: 12px;
  }
}
@media (max-width: 320px) {
  .tabcontent {
    /* display: none; */
    padding: 6px 0px;
    /* border: 1px solid #ccc; */
    border-top: none;
  }
  .backyard-logo {
    margin-left: -6% !important;
  }
  .cart-homepage-div {
    line-height: 2rem;
    top: 65% !important;
  }
  .contact-item-right-side {
    background-color: #292929;
    padding: 2rem 0.5rem;
    border: 1px dashed gray;
    color: white;
  }
  .upcoming-hours {
    background-color: #303030;
    /* margin-top: 40rem; */
  }
  .opening-hour-right-side-div {
    padding: 1rem 0.5rem;
  }
  .footer-send-input {
    padding: 0.8rem 0.5rem;
  }
  .our-story-img {
    margin-left: 1rem;
  }
  .menu-homepage-div {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-0%, -50%);
    font-size: 12px;
  }
}
@media (max-width: 280px) {
  .navbar-default {
    /* padding-top: 2rem; */
    align-self: center;
    padding: 1rem 0.7rem;
  }
  .backyard-logo {
    margin-left: -10% !important;
  }
  .cart-homepage-div {
    line-height: 1.5rem;
    top: 70% !important;
  }
  .homepage-heading {
    /* line-height: 1.5rem; */
    font-size: 20px;
  }
  .few-words-txt {
    font-size: 24px;
  }
  .menu-homepage-div {
    position: absolute;
    top: 50%;
    left: 18%;
    transform: translate(-0%, -50%);
    font-size: 10px;
  }
  .number-text {
    font-size: 20px;
  }
  .upcoming-right-side-div {
    padding: 1rem 2rem;
  }
  .footer-container {
    padding: 2rem 1rem;
  }
}
::-webkit-scrollbar {
  /* width: 0rem; */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.note-menu-items-div {
  /* display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.menu-notes-menu-page {
}
.menu-nav-item {
  display: none;
}
.sidemenu-hamburger {
  color: #222222;
}
.rmd-sheet {
  z-index: 99;
}
.blog-image {
  height: 60%;
  width: 100%;
}

.mobileview-why-people {
  display: none;
}
.dropbtn {
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  /* display: inline-block; */
}
/* .dropbtn:hover .dropdown-content1 {
  display: block;
} */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  width: 17rem;
  overflow: auto;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  top: 4rem;
  right: 0rem;
  padding: 0.5rem;
  border: 1px solid rgba(128, 128, 128, 0.103);
}
.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: white;
  width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a {
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: rgb(241, 197, 197);
}

.show {
  display: block;
}
.show1 {
  display: block;
}
.view-cart-btn {
  background-color: #222222;
  color: white;
  padding: 6px 0px;
  /* width: 95%; */
}
.navbar-cart-border {
  border-top: 0.5px solid #c3a16672;
  padding-top: 0.5rem;
}

.scrollmenu10 {
  overflow-y: scroll;
  height: 8rem;
  background-color: white;
}
.scrollmenu11 {
  overflow-y: scroll;
  height: 12rem;
  background-color: white;
  /* width: 102%; */
}
::-webkit-scrollbar {
  width: 0rem;
  background: transparent;
}
::-webkit-scrollbar {
  /* width: 0rem; */
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.hide {
  display: none;
}
.border-radio-form {
  border: 1px solid #c3a166;
  width: 50%;
  padding: 7px 7px;
}
.rmd-dialog--full-page {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 999;
}
.form-input {
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-bottom: 1px solid #271a13 !important;
}
.login-container {
  margin: 0 1rem;
}
.error-msg {
  color: red;
  /* background-color: rgba(0, 0, 0, 0.048); */
  /* margin-top: -3rem; */
  font-size: 10px;
  padding-top: 5px;
  /* padding-left: 5px; */

  /* animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: infinite; */
}
.continue-login-btn {
  background-color: #271a13;
  color: white;
  padding: 0.5rem 0;
  width: 100%;
  border-radius: 5px;
  margin-top: 1rem;
}
.looking-to-create-acc-div {
  font-size: 12px;

  margin-top: 1rem;
}
.dialogboxbtnmobileview {
  color: #271a13;
  padding-left: 1rem;
  display: none;
}
.dialogboxbtnmobileview2 {
  display: none;
}
.close3 {
  /* margin-left: 34rem; */
  float: right;
  text-align: right;
  font-size: 25px;
  cursor: pointer;
  color: #271a13;
  margin-top: 1rem;
  padding-right: 3rem;
}
body {
  text-transform: capitalize;
  background-color: white;
}
.home-nav-item {
  cursor: pointer;
}
.text-account {
  letter-spacing: 2px;
  color: black;

  align-self: center;
}
.backyard-logo {
  position: absolute;
  top: -0.9rem;
}
.accordion {
  cursor: pointer;

  outline: none;
  transition: 0.4s;
}

.panel {
  padding: 0 18px;
  font-size: 18px;
  background-color: #00000042;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.notification {
  position: relative;
}

.notification .badge {
  position: absolute;
  top: -10px;
  left: 0px;
  /* padding: 0px 1px; */
  padding-right: 6px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #fc8e2d;
  color: white;
}
.block {
  font-size: 10px;
}
.backyard-logo {
  /* position: absolute; */
  /* top: 1rem; */
  margin: auto;
  height: 12rem;
  /* margin-left: 6%; */
}
.mobile-view {
  display: none;
}
/* gallery */

/* .gallery-homepage .grid {
  display: grid;
  grid-template-columns: repeat(5, 200px);
  justify-content: center;
  align-content: center;
}

.gallery-homepage .grid img {
  width: 200px;
  height: 200px;
  cursor: pointer;
}
background-color: rgba(128,128,128,0.75);
#lightbox {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
}

#lightbox.active {
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* #lightbox img {
  max-width: 90%;
  max-height: 80%;
  padding: 4px;
  background-color: black;
  border: 2px solid white;
} */

/* Background Styles Only */
/* .demo4 {
  display: none;
} */
.gallery-homepage {
  /* width: 200px;
  
  cursor: pointer; */
  filter: brightness(0.4);
  height: 100% !important;
  width: 100%;
  background-size: contain;
  /* The least supported option. */
}
.slider-gallery-section {
  display: none;
}
.gallery-homepage2 {
  width: 500px !important;
  height: 300px !important;
  background-color: transparent;
}
.rmd-dialog-overlay {
  z-index: 99;
}
.rmd-dialog-container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: var(--rmd-dialog-vertical-margin, 1.5rem)
    var(--rmd-dialog-horizontal-margin, 2.5rem);
  pointer-events: none;
}
/* accrodion  */

.accordion {
  margin: 30px 0;
}
.accordion p {
  margin: 0;
}
.accordion-title {
  padding: 3px 20px;
}
.accordion-title {
  background: #243657;
  color: white;
  cursor: pointer;
}
.accordion-inner-wrap {
  border: 1px solid;
  margin-bottom: 15px;
}
.accordion-content {
  color: black;
  box-shadow: 0px 0px 0px 0px black;
  padding: 20px;
}
/********************
PLUS/MINUS SIGNS ON ACCORDION
********************/
.accordion-title {
  position: relative;
}
.accordion-indicator {
  position: absolute;
  transition: all 0.3s ease-in-out;
  background: white;
}
.accordion-indicator-minus {
  width: 20px;
  height: 3px;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
}
/********************
uncomment if only first is shown by default
********************/
.accordion-inner-wrap:first-of-type .accordion-indicator-plus {
  width: 3px;
  height: 20px;
  right: 30px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
}
.accordion-inner-wrap:first-of-type .active .accordion-indicator-plus {
  transform: translateY(-50%) rotate(90deg);
}
.accordion-indicator-plus {
  width: 3px;
  height: 20px;
  right: 30px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
.active .accordion-indicator-plus {
  transform: translateY(-50%) rotate(0deg);
}

.demo4 {
  display: none;
}
.iamge-slider-img-div-homepage {
  width: 100% !important;
  height: 20rem !important;
}
.blank-space-nav {
  background-color: white;
}
.order-items-image {
  height: 7.9rem;
  width: 7.9rem;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #c3a166;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.view-cart-bottom-bar {
  position: fixed;
  bottom: 0;
  z-index: 9;
  background-color: #292929;
  height: 4rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.fa-shopping-cart {
  color: white;
  font-size: 2rem;
}
